<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.buddyManagerCommissionAdd.mainForm" label-width="130px">
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 中文名 -->
            <el-form-item :label="$t('text.type')" prop="commission_type">
              <el-select v-model="mainForm.data.commission_type" class="width-fill" :placeholder="$t('placeholder.defaultSelect')">
                <el-option v-for="(item, index) in commissionTypeList" :key="index" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 团长手机号 -->
            <el-form-item :label="$t('form.buddy.buddyMobile')" prop="host_phone_number">
              <el-input
                v-model="mainForm.data.host_phone_number"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 提成金额（元） -->
            <el-form-item :label="$t('form.buddy.royaltyAmount')" prop="commission_amount_f">
              <el-input
                v-model="mainForm.data.commission_amount_f"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="24">
            <!-- 备注 -->
            <el-form-item :label="$t('form.buddy.remarks')">
              <el-input v-model="mainForm.data.remarks" type="textarea" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
