// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as hostCommissionsApi from '@/services/host-commissions.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'

export default {
  components: {
    importImg
  },
  data () {
    return {
      constant,
      dataDictionary,
      verifyRules: verifyRules(this),
      commissionTypeList: [],
      mainForm: {
        data: {
          commission_type: null,
          host_phone_number: null,
          commission_amount_f: null,
          remarks: null
        }
      }
    }
  },
  mounted () {
    const _this = this
    _this.setGlobalLoading(true)
    hostCommissionsApi.getCommissionTypeList().then(res => {
      _this.setGlobalLoading(false)
      _this.commissionTypeList = res.data.commission_type_list
    }).catch(() => {
      _this.setGlobalLoading(false)
    })
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            host_commission: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          hostCommissionsApi.postHostCommissions(requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'buddyManagerCommissionList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        }
      })
    }
  }
}
